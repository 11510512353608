.navbar {
  z-index: 100;
  position: fixed;
  width: 100%;

  &__sticky {
    z-index: 100;
    position: fixed;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 2px -2px gray;
  }

  &__space {
    display: flex;
    justify-content: space-between;
  }
}

.logo {
  font-weight: 900;
  font-size: 28px;
  background: transparent;
  color: black;
}

.right-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  &__title {
    font-weight: bold;
    font-size: 14px;
  }
}
