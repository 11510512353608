.top-section {
  background-image: url("../../assets/images/svg/moon.svg");
  height: 900px;
  position: relative;
  z-index: -5;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.logo-back {
  font-size: 332px;
  position: absolute;
  top: -150px;
  z-index: -1;
  opacity: 0.03;
  color: #764af1;
}

.illustration-paint {
  width: 530px;
  position: relative;
  top: 140px;

  @media (max-width: 767px) {
    top: 0;
  }
}

.service {
  @media (max-width: 767px) {
    text-align: center;
  }
  &__icon {
    filter: invert(42%) sepia(17%) saturate(7489%) hue-rotate(229deg)
      brightness(86%) contrast(87%);
  }

  &__text {
    word-wrap: normal;
  }
}

.top-content {
  position: absolute;
  margin-top: 26rem;
  width: 100%;

  &__title {
    font-size: 60px;
    font-weight: 500;
    color: #764af1;

    @media (max-width: 767px) {
      font-size: 40px;
      font-weight: 200px;
      margin: -18rem 0 4rem 0;
    }
  }

  &__subtitle {
    font-weight: 350;
  }

  &__image {
    float: right;
    position: relative;
    top: -310px;
    right: -150px;

    @media (max-width: 767px) {
      top: -10px;
      right: -150px;
    }
  }
}

.portofolio {
  margin: 8rem 0;
}
